@use "src/assets/scss/utils" as u;
@use "sass:meta";

.relative-normalize {
  a {
    @include u.transition('link-transition-default');
    @include u.mixins-hover() {
      color: u.var-color('secondary');
    }
  }

  @include u.font-family('Montserrat');

  line-height: u.size('line-height');
  color: u.var-color('text');

  * {
    font-family: inherit;
    line-height: inherit;
    box-sizing: border-box;
  }

  input, select, button, a {
    outline: none;
  }

  button {
    cursor: pointer;
  }

  svg {
    vertical-align: middle;
  }
}
